import { render, staticRenderFns } from "./SettingsPlaceholder.vue?vue&type=template&id=7fa73601&scoped=true&"
import script from "./SettingsPlaceholder.vue?vue&type=script&lang=js&"
export * from "./SettingsPlaceholder.vue?vue&type=script&lang=js&"
import style0 from "./SettingsPlaceholder.vue?vue&type=style&index=0&id=7fa73601&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa73601",
  null
  
)

export default component.exports
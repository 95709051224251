<template>
  <div>
    <talmundo-input
      :twolines="true"
      type="input"
      id="QuestionPlaceholder"
      :label="$t('form.placeholder')"
      :value="value"
      @changeValue="changeType($event, 'placeholder')"
      @updateValue="updateValue($event, 'placeholder')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      max: 120,
      open: false,
      value: ''
    };
  },
  mounted() {
    this.setValue();
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedQuestion'])
  },
  watch: {
    selectedQuestion() {
      this.setValue();
    }
  },
  methods: {
    setValue() {
      this.value =
        this.selectedQuestion && this.selectedQuestion.schema
          ? this.selectedQuestion.schema.data.placeholder.replace(/&amp;/gi, '&').replace(/<\/?[^>]+(>|$)/g, '')
          : '';
    },
    changeType(value, type) {
      this.value = value;
    },
    updateValue(value, type) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: value
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.select-wrapper {
  background-color: $color-tertiary;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  padding-left: 8px;
}

.material-icons {
  color: $color-midgreyblue;
  position: absolute;
  top: 8px;
  right: 8px;
}

label {
  color: $color-secondary;
  font-size: var(--text-size-small);
  line-height: 40px;
}

.options {
  height: 80px;
  overflow-y: scroll;
  background-color: $color-tertiary;
  width: 100%;

  div {
    padding: 8px;
  }
}
</style>
